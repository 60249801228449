import React, { FC, useEffect } from 'react';

import {
  CssBaseline,
  ITheme,
  ThemeName,
  ThemeProvider,
} from '@involve-software/uikit';
import { QueryClientProvider } from '@tanstack/react-query';

import { RouterProvider } from 'react-router-dom';

import { GlobalStyles } from 'styles/global';
import 'utils/i18n';
import queryClient from 'utils/query-client';
import { deskieWidget } from 'utils/support/deskieWidget';

import router from '../router';

import Head from './Head';
import SnackbarProvider from './Providers/Snackbar';

const themeKey = (process.env.REACT_APP_THEME as ThemeName) || 'kvitum';

type AppProps = { theme: ITheme };

const App: FC<AppProps> = ({ theme }) => {
  useEffect(() => {
    deskieWidget.add();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider themeKey={themeKey} theme={theme}>
        <SnackbarProvider>
          <Head />
          <CssBaseline />
          <GlobalStyles />
          <RouterProvider router={router} />
        </SnackbarProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
